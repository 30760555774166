import {Injectable, EventEmitter} from '@angular/core';

export interface ISearch {
  term: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public onTitleChange: EventEmitter<string> = new EventEmitter();
  public onHeaderToggle: EventEmitter<boolean> = new EventEmitter();

  /**
   * Service constructor
   * @constructor
   */
  constructor() {
  }

  /**
   * Encode text for SEO
   * @param {string} text Text to encode
   * @returns {string} Resulting text
   */
  static encodeText(text: string): string {
    return encodeURIComponent(text.replace(/ /g, '-'));
  }

  /**
   * Decode text for SEO
   * @param {string} text Text to decode
   * @returns {string} Resulting text
   */
  static decodeText(text: string): string {
    return decodeURIComponent(text.replace(/-/g, ' '));
  }

  /**
   * Sets application title
   * @param {string} title Application title
   */
  setTitle(title: string) {
    this.onTitleChange.emit(title);
  }

  /**
   * Shows application header
   */
  showHeader() {
    this.onHeaderToggle.emit(true);
  }

  /**
   * Hides application header
   */
  hideHeader() {
    this.onHeaderToggle.emit(false);
  }

}
